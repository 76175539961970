import { robotsNoIndex } from 'app/helpers';
import { SitePage } from 'app/layout';
import { Resources } from 'app/resources';

function ResourcesPage() {
  return (
    <SitePage>
      <Resources />
    </SitePage>
  );
}

export default robotsNoIndex(ResourcesPage);
